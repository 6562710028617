/*body {*/
  /*background-color: #282c34;*/
 /* background:linear-gradient(#f7f7f7, #f2f2f2, #ffead4);
  background-attachment: fixed;
}*/

.chatApp {
  text-align: center;
  /*max-width: 728px;*/
  margin: 0 auto;
}

.chatApp header {
    background-color: darkorange;
    /* height: 10vh;*/
    min-height: 50px;
    color: white;
    /*position: fixed;*/
    width: 100%;
    /*max-width: 728px;*/
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1000;
    padding: 10px;
    box-sizing: border-box;
    cursor: move;
}

.chatApp section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /*min-height: 100vh;*/
  /*background-color: rgb(40, 37, 53);  */
  background-color:white; 
}

.messagesMain {
    padding: 10px;
    height: 30vh;
    /*margin: 10vh 0 10vh;*/
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
}

.messagesMain::-webkit-scrollbar {
    width: 0.25rem;
}

.messagesMain::-webkit-scrollbar-track {
    background: white;
}

.messagesMain::-webkit-scrollbar-thumb {
    background: darkorange;
}

.scrollable::-webkit-scrollbar {
  width: 0.25rem;
}

.scrollableW::-webkit-scrollbar {
  width: 1rem;
}

.scrollable::-webkit-scrollbar-track {
  background: lightYellow;
}

.scrollable::-webkit-scrollbar-thumb {
  background: darkorange;
}

.sendMessageForm {
    /*height: 10vh;*/
    /*position: fixed;*/
    bottom: 0;
    background-color: darkorange /*rgb(24, 23, 23)*/;
    width: 100%;
    max-width: 728px;
    display: flex;
    font-size: 1.5rem;
}

.sendMessageForm button {
  width: 20%;
  background-color: darkorange;
}


.sendMessageForm textarea {
  line-height: 1;
  width: 100%;
  font-size: 1rem;
  /*border-top:solid 1px darkorange;
  border-bottom:solid 1px darkorange;
  border-right:solid 1px darkorange;
  border-left:none;*/
  border-radius: 3em;
  border:solid 1px darkorange; 
  /*background: rgb(58, 58, 58);*/
  /*color: white; */
  outline: none;
  /*border: none; */
  padding: 0 10px;
  margin: 5px 5px 5px 5px;
}

.sendMessageForm button {
  background-color: darkorange; /* Green */
  border: none;
  color: white;
  padding: 5px 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: 1.25rem;
}

.sendMessageForm button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}


.sign-in {
  color: #282c34;
  background: white;
  max-width: 400px;
  margin: 0 auto;
}




/*ul, li {
  text-align: left;
  list-style: none;
}*/

/*p {
  max-width: 500px;
  margin-bottom: 12px;
  line-height: 24px;
  padding: 10px 20px;
  border-radius: 25px;
  position: relative;
  color: white;
  text-align: center;
}*/

.message pre {
  max-width: 500px;
  margin-bottom: 12px;
  line-height: 24px;
  padding: 10px 20px;
  border-radius: 25px;
  position: relative;
  color: white;
  text-align: left;
}

.message {
  display: flex;
  /*align-items: center; */
}


.sent {
  flex-direction: row-reverse;
}

.sent pre {
  color: black;
  align-self: flex-end;
  border: solid 1px darkorange;
  white-space: pre-wrap;       /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
}

.received pre {
  color: black;
  border: solid 1px rgb(166, 26, 26);
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

.chatImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 2px 5px;
}

.chatBox {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #ccc;
    border: 1px solid darkorange;
    text-align: center;
    /*padding: 10px;*/
    box-sizing: border-box;
    margin-bottom: 10px;
    overflow: hidden;
    position: relative;
    margin: 20px;
    border-radius: 1em;
}

.chatBox .text {
  text-align: center;
}
